<template>
    <div class="d-flex " style="height:100vh">
        <!-- side bar -->
        <div class="border d-md-block d-none" style="max-width: 250px;width:250px;" id="mySidebar">
            <div class="text-center p-3 mb-3">
                <a href="../" class="nav-link"><img src="assets/images/spf-logo.png" alt="" class="img-fluid w-75"></a>
            </div>
            <!-- sidebar Menu -->

            <div class="w3-bar-block scrl-y "  style="padding-top: 13px;overflow:visble;padding-bottom: 13px;" v-bind:style="'max-height:'+  (sideHeight-50) +'px;'">
                <div class="text-black-50 px-3">
                    <p class="h7">MAIN</p>
                <div class="accordion accordion-flush border-0 " id="accordionFlushExample">
                    <div class="accordion-item text-black-50 ">
                      <h2 class="accordion-header " id="flush-headingOne">
                        <a class="accordion-button collapsed nav-link text-black-50 " type="button" data-bs-toggle="collapse" data-bs-target="#dashboard" aria-expanded="false" aria-controls="flush-collapseOne">
                            <span class="mdi mdi-view-dashboard-outline"> Dashboard</span>
                        </a>
                      </h2>
                      <div id="dashboard" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <a href="#/" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/home'?'active text-primary':''"><span class="mdi mdi-circle-medium"> Home</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/d-customer" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/d-customer'?'active text-primary':''"><span class="mdi mdi-circle-medium">Customer</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/d-supplier" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/d-supplier'?'active text-primary':''"><span class="mdi mdi-circle-medium">Supplier</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/customer" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/customer'?'active text-primary':''"><span class="mdi mdi-circle-medium">Data Customer</span><span class="mdi mdi-chevron-right"></span></a>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item text-black-50">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <a class="accordion-button collapsed accordion-button collapsed nav-link text-black-50" type="button" data-bs-toggle="collapse" data-bs-target="#sales" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span class="mdi mdi-chart-areaspline nav-link text-black-50"> Sales</span>
                        </a>
                      </h2>
                      <div id="sales" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <a href="#/target" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/target'?'active text-primary':''"><span class="mdi mdi-circle-medium">Target</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/penjualan" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/penjualan'?'active text-primary':''"><span class="mdi mdi-circle-medium">Penjualan Sales</span><span class="mdi mdi-chevron-right"></span></a>
                          </div>
                      </div>
                    </div>
                    <div class="accordion-item text-black-50">
                      <h2 class="accordion-header" id="flush_approved">
                        <a class="accordion-button collapsed accordion-button collapsed nav-link text-black-50" type="button" data-bs-toggle="collapse" data-bs-target="#approved" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span class="mdi mdi-clipboard-check-multiple-outline nav-link text-black-50"> Approved</span>
                        </a>
                      </h2>
                      <div id="approved" class="accordion-collapse collapse" aria-labelledby="flush_approved" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <a href="#/approved" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/approved'?'active text-primary':''"><span class="mdi mdi-circle-medium">Approved</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/approved-histori" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/approved-histori'?'active text-primary':''"><span class="mdi mdi-circle-medium">Approved History</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/pengajuan" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/approved-histori'?'active text-primary':''"><span class="mdi mdi-circle-medium">Pengajuan</span><span class="mdi mdi-chevron-right"></span></a>
                        </div>
                      </div>
                    </div>

                      <div class="accordion-item text-black-50 ">
                        <h2 class="accordion-header " id="flush-headingOne">
                          <a class="accordion-button collapsed nav-link text-black-50 " type="button" data-bs-toggle="collapse" data-bs-target="#olshop" aria-expanded="false" aria-controls="flush-collapseOne">
                              <span class="mdi mdi-store "> Dashboard Online</span>
                          </a>
                        </h2>
                        <div id="olshop" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                          <div class="accordion-body">
                              <a href="#/dashboard-barang" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/dashboard-barang'?'active text-primary':''"><span class="mdi mdi-circle-medium"> Produk</span><span class="mdi mdi-chevron-right"></span></a>
                              <a href="#/d-customer" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/d-customer'?'active text-primary':''"><span class="mdi mdi-circle-medium">Customer</span><span class="mdi mdi-chevron-right"></span></a>
                              <a href="#/d-supplier" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/d-supplier'?'active text-primary':''"><span class="mdi mdi-circle-medium">Supplier</span><span class="mdi mdi-chevron-right"></span></a>
                        </div>
                      </div>
                      </div>


                </div>
                </div>
            </div>

        </div>
        <div class="col-md col-12">
            <div class="d-flex flex-row justify-content-between border">
                <div class="p-2 d-flex col-md align-items-center">
                    <button class="btn p-0 ms-2 me-4 d-md-none d-block" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions"><i class="mdi mdi-menu h3 text-black-50"></i></button>
                    </div>
                <div class="p-2 me-2   d-flex justify-content-end col-md align-items-center">
                    <div class="dropdown">
                        <a class="nav-link d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <p class="mb-0 text-black-50 fw-bold">Welcome, {{username}}</p>
                            <img src="assets/images/admin.png" class="rounded-circle img-fluids" style="width: 32px;">
                        </a>
                        <ul class="dropdown-menu">
                          <li><a class="dropdown-item" v-on:click="loadProfile()"><span class="mdi mdi-cog text-black-50"> Setting</span></a></li>
                          <li><a class="dropdown-item" v-on:click="logoutSystem()"><span class="mdi mdi-logout-variant text-black-50"> Logout</span></a></li>
                        </ul>
                      </div>
                </div>
            </div>
            <div class="p-0">
                <keep-alive>
                <router-view class="pt-1 mt-0 scrl-y" style="overflow: auto;" ></router-view>
                </keep-alive>
            </div>
        </div>
        <!-- sidebar -->
        <div class="d-md-none d-block">
            <div class="offcanvas offcanvas-start  w-50" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                <div class="offcanvas-header">
                    <div class="text-center p-3 mb-3">
                        <a href="../" class="nav-link"><img src="assets/images/spf-logo.png" alt="" class="img-fluid w-75"></a>
                    </div>
                </div>
                <div class="offcanvas-body">
                  <div class="accordion accordion-flush border-0 " id="accordionFlushExample">
                    <div class="accordion-item text-black-50 ">
                      <h2 class="accordion-header " id="flush-headingOne">
                        <a class="accordion-button collapsed nav-link text-black-50 " type="button" data-bs-toggle="collapse" data-bs-target="#dashboard" aria-expanded="false" aria-controls="flush-collapseOne">
                            <span class="mdi mdi-view-dashboard-outline"> Dashboard</span>
                        </a>
                      </h2>
                      <div id="dashboard" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <a href="#/" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/home'?'active text-primary':''"><span class="mdi mdi-circle-medium"> Home</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/d-customer" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/d-customer'?'active text-primary':''"><span class="mdi mdi-circle-medium">Customer</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/d-supplier" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/d-supplier'?'active text-primary':''"><span class="mdi mdi-circle-medium">Supplier</span><span class="mdi mdi-chevron-right"></span></a>
                            <!-- <a href="#/checkin" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/checkin'?'active text-primary':''"><span class="mdi mdi-circle-medium">Checkin</span><span class="mdi mdi-chevron-right"></span></a> -->
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item text-black-50">
                      <h2 class="accordion-header" id="flush-headingThree">
                        <a class="accordion-button collapsed accordion-button collapsed nav-link text-black-50" type="button" data-bs-toggle="collapse" data-bs-target="#sales" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span class="mdi mdi-chart-areaspline nav-link text-black-50"> Sales</span>
                        </a>
                      </h2>
                      <div id="sales" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <a href="#/target" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/target'?'active text-primary':''"><span class="mdi mdi-circle-medium">Target</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/penjualan" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/penjualan'?'active text-primary':''"><span class="mdi mdi-circle-medium">Penjualan Sales</span><span class="mdi mdi-chevron-right"></span></a>
                         </div>
                      </div>
                    </div>
                    <div class="accordion-item text-black-50">
                      <h2 class="accordion-header" id="flush_approved">
                        <a class="accordion-button collapsed accordion-button collapsed nav-link text-black-50" type="button" data-bs-toggle="collapse" data-bs-target="#approved" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span class="mdi mdi-clipboard-check-multiple-outline nav-link text-black-50"> Approved</span>
                        </a>
                      </h2>
                      <div id="approved" class="accordion-collapse collapse" aria-labelledby="flush_approved" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">
                            <a href="#/approved" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/approved'?'active text-primary':''"><span class="mdi mdi-circle-medium">Approved</span><span class="mdi mdi-chevron-right"></span></a>
                            <a href="#/approved-histori" class="nav-item nav-link d-flex justify-content-between mb-3" :class="menuinduk=='/approved-histori'?'active text-primary':''"><span class="mdi mdi-circle-medium">Approved History</span><span class="mdi mdi-chevron-right"></span></a>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
              </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "Dashboard",
    components: {},
    data() {
        return {
            sideHeight: 0,
            username: "",
            panel: false,
            appshow: false,
            menus: [],
            // pr_code: localStorage.program,
            menuinduk: localStorage.menuinduk,
            level: localStorage.tsaleslevel,
            txtcari: "",
            selectedRow: '',
            namaapp: this.appName
        };
    },
    methods: {
        loadMenu() {
            let self = this;
            // let obj = await self.deAsync("/tproject/admin/getmenu", {
            //     pr_code: self.pr_code,
            // });
            self.username = localStorage.username;
            // self.menus = obj.data.rec;
        },
        openMenu() {
            var mySidebar = document.getElementById("mySidebar");
            var overlayBg = document.getElementById("myOverlay");

            if (
                mySidebar.style.display === "block!important" ||
                mySidebar.style.display === "block"
            ) {
                mySidebar.style.display = "none";
                overlayBg.style.display = "none";
            } else {
                mySidebar.style.display = "block";
                overlayBg.style.display = "block";
            }
        },
        closeMenu() {
            let mySidebar = document.getElementById("mySidebar");
            let overlayBg = document.getElementById("myOverlay");
            mySidebar.style.display = "none";
            // overlayBg.style.display = "none";
        },
        getIcon(mn) {
            if (mn.mn_type == 'P') {
                if (mn.mn_icon != "" && mn.mn_icon != null && mn.mn_icon != "-") {
                    return mn.mn_icon;
                } else {
                    return "fa fa-book";
                }
            } else {
                return '';
            }
        },
        logoutSystem() {
            var self = this;
            swal(
                {
                    title: "Keluar Program",
                    text: "Yakin Ingin Keluar Program ..!",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Ya, Keluar",
                    cancelButtonText: "Tidak",
                    closeOnConfirm: true,
                    closeOnCancel: true,
                },
                function (isConfirm) {
                    if (isConfirm) {
                        localStorage.clear();
                        self.$router.push("/../login");
                        location.reload();
                    }
                }
            );
        },
        loadProfile() {
            this.$router.push("/profile");
        }
    },
    watch: {
        $route: function () {
            this.menuinduk = this.$route.path;
            this.closeMenu();
        },
    },
    mounted() {
        this.sideHeight = window.innerHeight;
        this.menuinduk = this.$route.path;
        // this.namaapp = this.appName;
        this.loadMenu();
    },
};
</script>

